<template>
  <v-icon :icon="icon" />
</template>

<script lang="ts" setup generic="I">
import { get } from 'lodash';
import type { TableCellProp } from '@/types/table';

const props = defineProps<TableCellProp<I>>();
const icon = computed(() => {
  const cellValue = get(props.rowItem, props.colKey);

  return cellValue ? 'i-mdi:checkbox-marked' : 'i-mdi:checkbox-blank-outline';
});
</script>
